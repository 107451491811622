@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.contact-hero {
    width: 100%;
    height: 100dvh;
    position: relative;
    background: rgba(0, 0, 0, 0.301);
  }
  
  .contact-hero:before {
    content: '';
    position: absolute;
    background: url('../../assets/executive_search_bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.contact-hero .contact-hero-content {
    position: absolute;
    width: 40%;
    bottom: 20%;
    left: 10%;
    padding: 1rem;
}

.contact-hero h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    text-align: left;
}

.contact-hero h2 {
    font-size: 1rem;
    padding: .5rem 0;
    color: white;
    font-weight: normal;
}

.contact-hero p {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 2rem;
}


/* MEDIA QUERIES */



@media screen and (max-width:940px) {

.contact-hero .contact-hero-content {
    position: absolute;
    width: 100%;
    top: 60%;
    left: 0%;
    padding: 1rem;
}

.contact-hero h1 {
    text-align: center;
    font-size: 2rem;
}

.contact-hero p {
    text-align: center;
}

}


@media screen and (max-width:700px) {


    .contact-hero h2 {
        text-align: center;
    }
    

    }