@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Hebrew:wght@300&display=swap');

.section1 {
    width: 100%;
    height: auto;
    padding: 2rem 0;
    background: #67a8dd;
    background: linear-gradient(90deg, #67a8dd 0%, rgba(40, 92, 134, 0.863) 74%);    

}

.section1-header {
    text-align: center;
    padding: 1rem;
}

.section1-header h1 {
    font-size: 3rem;
    padding: 1rem;
    color: white;
    letter-spacing: 3px;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
}

.section1-header p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: white;

}

/* cards */

.card {
    background-color: white;
    width: 300px;
    padding: .5rem;
    border-radius: 5px;
}

.card img {
    width: 100%;
}

.section1-cards {
    padding: 1rem;
}

.card:hover {
    box-shadow: 2px 13px 18px -14px rgba(23,22,23,1);
}

.card h1 {
    padding: .5rem;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
}

.card p {
    text-align: center;
    font-size: 14px;
    line-height: normal;
    color: rgb(24, 23, 23);
    font-family: 'roboto';
    padding: .5rem;
}


.wwa-cards-contents {
    display: flex; 
    justify-content: center;
    gap: 1rem;
}





@media screen and (max-width:940px) {
    
    .wwa-cards-contents {
        flex-wrap: wrap;
    }

    .card {
        width: 30%;
    }
}


@media screen and (max-width: 600px) {
    .card {
        width: 75%;
    }

    .section1-header h1 {
        font-size: 2rem;
    }
}