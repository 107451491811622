
.sectionTwo {
  background: linear-gradient(90deg, #4e82ac 0%, rgba(40, 92, 134, 0.863) 74%);    
  padding: 2rem 0;
  margin-top: -.2rem;
}

.sectionTwoHeader h1 {
    color: white;
    text-align: center;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.2rem;
}

.sectionTwoFlex {
    display: flex;
    justify-content: center; 
    gap: 1rem;
    padding: 1rem;
}


.sectionTwoFlexItem {
    width: 25%;
    border: 2px solid rgb(236, 236, 241);
    width: 100%;
    padding: 3rem;
}


.sectionTwoFlexItem h1 {
    text-align: center;
    font-size: 4rem;
}

.sectionTwoFlexItem h2 {
    text-align: center;
    font-size: 1rem;
    color: white;
    font-weight: normal;
    padding: 2rem 0;
}




@media screen and (max-width: 940px) {
    .sectionTwoFlex {
        flex-wrap: wrap;
    }

    .sectionTwoFlexItem {
        width: 90%;
        padding: 2rem;
    }


}





