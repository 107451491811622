@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --primary-color: #67a8dd;
  ;
  --secondary-color: #db7a0a;

}

@font-face {
  font-family: "Wildest";
  src: local("Wildest"),
   url("../src/components/fonts/Wildest.ttf") format("truetype");
  font-weight: bold;
 }


span {
  color: var(--secondary-color);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: 'Roboto', sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: white;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ebf1f3;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


p {
  font-size: 24px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  color: var(--primary-color);

}

h2 {
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  color: var(--primary-color);
}

.container {
  max-width: 1240px;
  margin: auto;
  height: 100%;
  position: relative;
}

button {
  font-family: 'Roboto', sans-serif;
  margin: 1rem 0;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: #f8f8f8;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: .4rem;
  cursor:pointer;

}

/* button:hover {
  background-color: #530e74;
  border: 1px solid #530e74;
} */

span {
  color: var(--primary-color);
}


/* SUBMENU */

.sub__menus__arrows:hover .sub__menus{
  display: block;
}

.sub__menus__arrows__full:hover .sub__menus{
  display: block;
  z-index: 1000000;
}

.menu-item .sub__menus  a {
padding: 7px 0 0px 0;
margin: 0px 5px;
}

p {
      font-size: 15px;
  }




  /* MEDIA QUERIES */
  
  @media screen and (max-width:940px) {

    p {
        font-size: 14px;
        text-align: center;
    }



}

@media screen and (max-width:375px) {
  .box img {
      width: 100%;
      height: 300px;
  }
}



@media screen and (max-width:940px) {

  .main-menu h1 {
      font-size: 1.5rem;
  }

  .main-menu-header h1{
      font-size: 1.5rem;
  }
  
  .main-menu-content p{
      font-size: 14px;
  }

  .main-menu .content {
    font-size: 14px;
    padding: 0 2rem;
}
}


