

.SolutionsCards {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3rem 1rem;
  gap: 1rem;
}

.SolutionsCard {
  display: flex;
  justify-content: center;
  height: 300px;
  width: 30%;
  transform-style: preserve-3d;
}

.front {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  background-image: linear-gradient(45deg, rgb(58, 58, 58), rgb(22, 22, 22));
  transition: 0.7s;
  text-align: center;
}

.back {
  position: absolute;
  color: whitesmoke;
  text-align: center;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: rotateX(-180deg);
  background-image: linear-gradient(45deg, rgb(36, 36, 36), rgb(71, 71, 71));
  transition: 0.7s;
  padding: .5rem;
}

.SolutionsCard:hover .back {
  transform: rotateX(0deg);
}

.front h4 {
  color: white;
  font-size: 22px;
  font-weight: 400;
  transform: translateZ(50px);
  padding: .5rem;
}
.back h3 {
  display: inline-block;
  margin: 50px auto 30px auto;
  transform: translateZ(50px);
  color: rgb(241, 235, 235);
}

.back p {
  transform: translateZ(50px);
  font-size: 12px;
}

.service1 .front {
  background: linear-gradient(45deg, rgb(91 108 196), rgb(139 175 217));
}

.service2 .front {
  background: linear-gradient(45deg, rgb(244 165 192), rgb(143 87 177));
}

.service3 .front {
  background: linear-gradient(45deg, rgb(203 106 153), rgb(217 181 134));
}
.SolutionsCard:hover .front {
  transform: rotateX(180deg);
}

.footer {
  text-align: center;
  font-weight: 400;
  padding: 0 auto;
}

.footer span {
  color: rgb(255, 255, 255);
}






@media screen and (max-width: 700px) {
    .SolutionsCard {
        width: 100%;
   }

   .SolutionsCards {
    flex-wrap: wrap;
  }
}