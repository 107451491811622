/* SideTabs.css */

.sideTabs {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 3rem 0;
  background: #e5e8eb79;
  /* background: linear-gradient(90deg, #67a8dd 0%, rgba(40, 92, 134, 0.863) 74%);     */

}

.sideTabs:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg2.webp') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.09;
}

.sideTabsHeader {
  text-align: center;
  padding: 1rem;
}
.sideTabsHeader h1 {
  font-weight: normal;
  color: rgb(31, 29, 29);
  padding: 1rem 0;
}

.sideTabsHeader p {
  color: rgb(31, 29, 29);
}


.side-tabs-container {
  display: flex;
  height: auto;
  padding: 1rem;
}

.tabs {
  display: flex;
  flex-direction: column;
  width: 40%;
  border-right: 1px solid #ccc;
}

.tabs h1 {
  font-size: 1.5rem;
  font-weight: normal;
}

.tabs h2 {
  font-size: .8rem;
  font-weight: normal;
  color: rgb(247, 240, 240);
}

.tab {
  padding: 20px;
  cursor: pointer;
  background-color: var(--primary-color);
  border-bottom: 1px solid #ccc;
  color: rgb(247, 240, 240);

}

.tab:hover {
  background-color: #077ba8;
}

.tab.active {
  background-color: #48a6cc;
  font-family: "Roboto";
}

.sideTabContent {
  padding: 0 20px;
  flex-grow: 1;
}

.sideTabContent h1 {
  font-weight: normal;
  text-align: center;
  font-size: 2rem;
  padding: .5rem;
  color: var(--primary-color);
}

.sideTabContent h2 {
  font-weight: normal;
  text-align: center;
  font-size: 2rem;
  color: #48a6cc;
}

.sideTabContent h3 {
  font-size: 1rem;
  padding: .5rem 0;
  color: var(--primary-color);
  font-weight: normal;
}

.sideTabContent p {
  text-align: left;
  color: rgb(31, 29, 29);
}

.sideTabContent ul li {
  padding: .5rem 0;
}


@media  screen and (max-width:940px) {

}