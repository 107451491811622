.contact-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 1rem;
}

.contactFormLeft {
  width: 70%;
  height: 100%;
  margin: auto;
}

.contactFormLeft img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.contactFormRight {
  width: 100%;
  margin: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
}

.get_form_inner_text h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.get_form_inner_text p {
  font-style: italic;
}

.inputs {
  display: flex;
  flex-direction: column;
}

.inputs label {
  margin: 10px 0 5px;
}

.userTypeLabel {
  font-weight: normal;
  font-size: 14px;
}

select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

select:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
    border-color: var(--primary-color);
    outline: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  resize: vertical;
}

input[type="submit"] {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}

.disclaimer p {
  padding: 1rem 0;
  font-size: 12px;
}

.disclaimer span {
  padding: 1rem 0;
  font-size: 12px;
  color: var(--primary-color);
}
  

@media screen and (max-width: 940px) {
  .contact-container {
    flex-wrap: wrap-reverse;
  }
  
}


@media screen and (max-width: 600px) {
  .contactFormLeft {
    width: 100%;
  }
}

