@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.careers {
    width: 100%;
    height: 100vh;
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(0, 0, 0, 0.253);
  }
  
  .careers:before {
    content: '';
    position: absolute;
    background: url('../../assets/careers_bg.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.careers .careers-content {
    display: block;
    position: absolute;
    bottom: 20%;
    padding: 1rem;
    width: 100%;
}

.careers h1 {
    font-size: 4rem;
    font-family: 'Raleway', sans-serif;
    color: white;
    font-weight: normal;
    text-align: center;
}

.careers p {
    text-align: center;
    color: white;
    padding: .5rem 0;
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {


.careers h1 {
    font-size: 2.5rem;
    line-height: 2.5rem;
}
}


@media screen and (max-width:700px) {
    
    .careers .careers-content {
        bottom: 10%;
    }

}