




.solutionsSection {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 5rem 1rem 0 1rem;
}

.solutionsSection h1 {
  font-size: 3rem;
  padding: 1rem 0;
  color: var(--primary-color);
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

.solutionsSection  p {
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
}




@media screen and (max-width: 940px) {


}






