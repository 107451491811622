.expertiseSection4 {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.expertiseSection4:before {
  content: '';
  position: absolute;
  background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: .02;
}

.expertiseSection4Content {
  padding: 1rem;
  width: 100%;
}

.expertiseSection4Flex {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.expertiseSection4FlexRight {
  width: 50%;
  margin: auto;
}

.expertiseSection4FlexRight h1 {
  text-align: left;
  font-weight: normal;
  padding: .5rem 0;
} 

.expertiseSection4FlexRight h2 {
  text-align: left;
  font-weight: normal;
  font-size: 1rem;
  padding: .5rem 0;
} 

.expertiseSection4FlexRight p {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: rgb(65, 66, 66);
  text-align: justify;
}

.expertiseSection4FlexLeft {
  width: 50%;
  margin: auto;
}

.expertiseSection4FlexLeft img {
  width: 100%;
  border-radius: 1rem;
}


@media  screen and (max-width:940px) {

  .expertiseSection4Flex {
    flex-wrap: wrap;
  }

  .expertiseSection4FlexRight {
      width: 100%;
    }

    .expertiseSection4FlexLeft {
      width: 90%;
    }

    .expertiseSection4Flex {
      flex-wrap: wrap-reverse;
    }

}