.HealthcareExecutiveSearch2 {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(29, 28, 29, 0);
  }
  
  .HealthcareExecutiveSearch2:before {
    content: '';
    position: absolute;
    background: url('../../assets/section-bg.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .08;
  }


 

.HealthcareExecutiveSearch2Content {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 3rem 1rem;
}


.sHealthcareExecutiveSearch2ContentLeft {
    width: 100%;
    margin: auto;
}

.sHealthcareExecutiveSearch2ContentLeft img {
    width: 100%;
    height: auto;
}

.HealthcareExecutiveSearch2ContentRight {
    width: 100%;
    margin: auto;
}


.HealthcareExecutiveSearch2ContentRight h1 {
    font-size: 1.5rem;
    padding: 1rem 0;
    font-weight: normal;
}

.HealthcareExecutiveSearch2ContentRight h2 {
    font-size: 1rem;
    font-weight: normal;
    padding: .5rem 0;
}



.HealthcareExecutiveSearch2ContentRight p {
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    color: var(--primary-text);
    font-size: 18px;
}

.HealthcareExecutiveSearch2ContentRight ul {
    padding: .5rem 0;
}

.HealthcareExecutiveSearch2ContentRight ul b{
    color: var(--primary-color);
    font-weight: normal;
}


.section1-link {
    text-align: center;    
}

.section1-link p span {
    font-size: 16px;
    color: var(--primary-color);
}

.section1-link p span:hover {
    color: orange;
}



@media screen and (max-width:940px)  {

    .HealthcareExecutiveSearch2Content {
        flex-wrap: wrap-reverse;
    }
    

    .HealthcareExecutiveSearch2ContentRight {
        width: 90%;
    }
    
    .HealthcareExecutiveSearch2ContentRight h1 {
        text-align: center;
    }
    
}

