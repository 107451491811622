@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.solutions {
    width: 100%;
    height: 100dvh;
    position: relative;
    background: rgba(0, 0, 0, 0.226);
  }
  
  .solutions:before {
    content: '';
    position: absolute;
    background: url('../../assets/submenu-bg-team.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.solutionsContent {
    width: 50%;
    display: block;
    position: absolute;
    bottom: 10%;
    padding: 1rem;
}


.solutions h1 {
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
    color: white;
    text-align: center;
    line-height: 3rem;
}



/* MEDIA QUERIES */

@media screen and (max-width:940px) {

.solutionsContent {
        width: 100%;
        display: block;
        position: absolute;
        padding: 1rem;
    }

        .solutions h1 {
            font-size: 2rem;
            text-align: center;
            line-height: 2rem;
        }
}
