@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.healthcareExecutiveSearch {
    width: 100%;
    height: 100vh;
    position: relative;
    background: rgba(3, 3, 3, 0.192);
  }
  
  .healthcareExecutiveSearch:before {
    content: '';
    position: absolute;
    background: url('../../assets/hero4.webp') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  
.healthcareExecutiveSearchContent {
    position: absolute;
    width: 40%;
    bottom: 20%;
    left: 10%;
    padding: 1rem;
}

.healthcareExecutiveSearchContent h1 {
    font-size: 3.5rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    text-align: left;
}

.healthcareExecutiveSearchContent h2 {
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    color: rgb(243, 236, 236);
    padding: 1rem 0;
    text-align: left;
    letter-spacing: 3px;
}

.healthcareExecutiveSearchContent p {
    font-family: 'Montserrat', sans-serif;
    color: rgb(233, 233, 233);
}

.healthcareExecutiveSearchContent span {
    color: white;
}

/* MEDIA QUERIES */



@media screen and (max-width:940px) {

.healthcareExecutiveSearchContent {
    position: absolute;
    width: 100%;
    top: 60%;
    left: 0%;
    padding: 1rem;
}

.healthcareExecutiveSearchContent h1 {
    text-align: center;
    font-size: 2rem;
}

.healthcareExecutiveSearchContent h2 {
    text-align: center;
    font-size: 2rem;
}

.healthcareExecutiveSearchContent p {
    text-align: center;
}

}


@media screen and (max-width:700px) {

    .healthcareExecutiveSearchContent {
        height: 100vh;
      }

    .healthcareExecutiveSearchContent p {
        font-size: 14px;
    }

    }