@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.ExpertiseSectionOverview {
    padding: 3rem 1rem;
}

.ExpertiseSectionOverview p {
    padding: .5rem 0;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: rgb(65, 66, 66);
}

.ExpertiseSectionOverview h1 {
    text-align: left;
    font-weight: normal;
    padding: .5rem 0;
}

.ExpertiseSectionOverview h2 {
    text-align: left;
    font-weight: normal;
    font-size: 1rem;
    padding: .5rem 0;
  }

