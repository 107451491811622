.careersHome {
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, 0);
  }
  
  .careersHome:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }


.careersHomeFlex {
    bottom: 0%;
    justify-content: center;
}

.careersHomeLeft {
    width: 100%;
    margin: auto;

}

.careersHomeLeft img {
    width: 100%;
    margin-bottom: -.25rem;
}

.careersHomeLeft h1 {
    text-align: center;
    font-size: 2rem;
    padding: 2rem .5rem;
    font-weight: normal;
}




@media screen and (max-width:940px) {
    .careersHomeFlex {
        flex-wrap: wrap;
    }

    .careersHomeLeft {
        width: 100%;
    }  

    .careersHomeLeft h1 {
        font-size: 2rem;
    }

}

@media screen and (max-width:600px) {
    .careersHomeLeft h1 {
        font-size: 1.5rem;
    }
}